import { PCPropTypes } from '@hrk/huw-module-library'
import { AppLocale } from '@hrk/types'
import { graphql, useStaticQuery } from 'gatsby'
import { Query } from 'gatsbyGraphqlTypes'
import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Privacy: React.FC = () => {
  const currentSlug = '/en/data-privacy'
  const currentLocale: AppLocale = 'en'

  const site: SiteMetadata = useSiteMetadata(currentLocale)
  const { collectBreadCrumbs } = useNavigationItems()

  const {
    strapi: { pageContents: data },
  } = useStaticQuery<Query>(
    graphql`
      query {
        strapi {
          pageContents(locale: "all", filters: { slug: { eq: "/datenschutz" } }) {
            data {
              id
              attributes {
                slug
                title
                description
                locale
                localizations {
                  data {
                    attributes {
                      locale
                      slug
                    }
                  }
                }
                exportJSON
              }
            }
          }
        }
      }
    `,
  )

  if (!data || !data.data.length) return <></>

  const title = data.data[0]?.attributes?.title ?? site.config.defaultTitle
  const description = data.data[0]?.attributes?.description ?? site.config.defaultDescription
  const style = {
    fontWeight: 'bold',
    lineHeight: '24px',
    fontSize: '13px',
    padding: '0.8em 1.4em',
    margin: '0 0 1em 0',
    border: '1px solid #990000',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#990000',
    backgroundColor: 'white',
  }
  const cookieConsentButton = React.createElement(
    'button',
    {
      style,
      id: 'Privacy-Cookie-Center-btn',
      title: 'Cookie-Center',
      'aria-label': 'Cookie-Center',
      type: 'button',
      'data-cc': 'c-settings',
      onClick: () => {
        if (typeof document !== 'undefined') {
          document.documentElement.classList.toggle('show--settings')
          document.getElementById('s-cnt')?.setAttribute('aria-hidden', 'false')
        }
      },
    },
    `Cookie-Center`,
  )
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title, type: 'EditorialPages' })

  return (
    <>
      <Seo title={title} slug={currentSlug} locale={currentLocale} description={description}></Seo>
      <LayoutWrapper
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        contentItems={data.data[0]!.attributes?.exportJSON as PCPropTypes[]}
        currentTitle={title}
        currentLocale={currentLocale}
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: 'de', slug: '/datenschutz/' }]}
        preChildren={cookieConsentButton}
      ></LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/data-privacy/`} />
}

export default Privacy
